export default defineNuxtRouteMiddleware(async (to) => {
  const { $api } = useNuxtApp()

  try {
    const { data } = await $api.v3.userHouses.read(to.params.name)
    const auth = useState('auth')
    const isOwner = auth.value?.user.ownedHousesIds.includes(Number(data.id))

    if (!isOwner) {
      return navigateTo({ name: 'index' })
    }
  } catch (err) {
    console.error(err)
  }
})
